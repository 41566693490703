export const filterType = {
  employeeFilter: 'employee-filter',
  projectFilter: 'project-filter',
  statusFilter: 'status-filter',
  timeFilter: 'time-filter',
};

export const matrixFilterTooltips = {
  OVR_MULTIPLIER: 'Shows total amount of hours either with actual overtime logged and overtime hours multiplied',
  HIDE_EMPTY: 'Hides the records without data',
  BILLABLE_ONLY: "Shows the records with attribute 'Billable = YES' only",
  APPROVED_ONLY: "Shows the records with 'Status = Approved' only",
  UNAPPROVED_ONLY: "Shows the records with 'Status = Draft, Submitted, Declined' only",
};
