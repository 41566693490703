<template>
  <div>
    <v-virtual-data-table
      id="matrix-report-table"
      key="matrix-report-table"
      ref="virtualTable"
      hide-default-header
      hide-default-footer
      fixed-header
      :dense="denseTables"
      :headers="filtered.projects"
      :rows="filtered.employees"
      :height="defaultTableHeight"
    >
      <template #headerRow="{ headers: projects }">
        <th class="employee_info-col no-padding sticky sticky_top sticky_left vt-offset-x">
          <table>
            <tr>
              <td class="column text-start employee_name-col">
                Employee
              </td>
              <td class="column text-end grey lighten-4 employee_total-col">
                <span>Total</span>
              </td>
            </tr>
          </table>
        </th>
        <th
          v-for="(project, index) in projects"
          :key="index"
          class="column text-start project-column"
        >
          <v-tooltip top>
            <template #activator="{ on }">
              <span v-on="on">{{ project.title }}</span>
            </template>
            <span>{{ project.title }}</span>
          </v-tooltip>
        </th>
      </template>

      <template #row="{ row: employee, headers: projects }">
        <th class="employee_info-col no-padding sticky sticky_left">
          <table>
            <tr>
              <td
                class="column text-start employee_name-col"
                :class="{ 'amber lighten-2' : isEmployeeProjectOverLimit(employee) }"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span v-on="on">{{ employee.name }}</span>
                  </template>
                  <span>{{ employee.name }}</span>
                </v-tooltip>
              </td>
              <td class="column text-end grey lighten-4 employee_total-col">
                <span>{{ getTimeFromHours(totalHoursByEmployees[employee.id], '0h') }}</span>
              </td>
            </tr>
          </table>
        </th>
        <td
          v-for="(project, index) in projects"
          :key="index"
          class="project-column column text-start"
          :class="{
            'amber lighten-2' : isOverLimit(employee, project),
            'grey lighten-2' : !!getTimeFromHours(employee.projects_hours[project.id], false),
          }"
        >
          {{ getTimeFromHours(employee.projects_hours[project.id]) }}
        </td>
      </template>

      <template #body.append="{ headers: projects }">
        <tr class="total-row grey vt-offset-y">
          <th class="employee_info-col no-padding sticky sticky_left sticky_bottom">
            <table>
              <tr>
                <td class="column text-start employee_name-col">
                  <span>Total project time:</span>
                </td>
                <td class="column text-end employee_total-col">
                  <span>{{ getTimeFromHours(commonTotalHours) }}</span>
                </td>
              </tr>
            </table>
          </th>
          <th
            v-for="(project, index) in projects"
            :key="index"
            class="project-column sticky sticky_bottom"
          >
            {{ getTimeFromHours(totalHoursByProjects[project.id], '0h') }}
          </th>
        </tr>
      </template>
    </v-virtual-data-table>

    <v-divider />
    <v-row class="table-footer">
      <div class="footer-text">Summary: {{ totalEmployees }} employees; {{ totalProjects }} projects</div>
    </v-row>
  </div>
</template>

<script>
import has from 'lodash/has';
import { mapGetters } from 'vuex';

import VVirtualDataTable from '@/components/shared/VVirtualDataTable.vue';
import absenceTitles from '@/constants/absenceTitles';
import { defaultTableHeight } from '@/constants/tableHeights';

export default {
  components: {
    VVirtualDataTable,
  },
  props: {
    highlight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultTableHeight,
      commonTotalHours: undefined,
      totalHoursByProjects: {},
      totalHoursByEmployees: {},
    };
  },
  computed: {
    ...mapGetters('matrixReport/filters', [
      'employeeFilter',
      'employeeStatusFilter',
      'employeeStatusFilterMap',
      'employeeOfficeFilter',
      'projectFilter',
      'isOVRMultiplyerApplied',
      'isEmptyHidden',
    ]),
    ...mapGetters('matrixReport/report', ['matrixReport']),
    ...mapGetters('projects/main', ['projects']),
    ...mapGetters('user/settings', [
      'denseTables',
    ]),

    filledProjectsMap() {
      /* eslint-disable no-param-reassign */
      return this.matrixEmployees.reduce((map, empl) => {
        const projects = Object.keys(empl.projects_hours);

        projects.forEach((el) => (map[el] = true));

        return map;
      }, {});
      /* eslint-enable */
    },

    employeeOfficeFilterMap() {
      if (!this.employeeOfficeFilter.length) return {};

      /* eslint-disable no-param-reassign */
      const officeFilterMap = this.employeeOfficeFilter.reduce((officesMap, officeId) => {
        officesMap[officeId] = officeId;

        return officesMap;
      }, {});
      /* eslint-enable */

      return officeFilterMap;
    },

    matrixEmployees() {
      if (!this.matrixReport.employees) return [];

      return this.matrixReport.employees
        .map((employee) => {
          for (const projectId in employee.projects_hours) {
            if (projectId === '*' || !has(employee.projects_hours, projectId)) continue;

            this.setVisualisedHours(employee.projects_hours[projectId]);
          }

          return employee;
        })
        .sort(({ name: leftEmployeeName }, { name: rightEmployeeName }) => {
          if (leftEmployeeName === rightEmployeeName) return 0;

          return leftEmployeeName > rightEmployeeName ? 1 : -1;
        });
    },
    filteredEmployees() {
      const employeeNameFilter = this.employeeFilter.trim();

      if (
        !employeeNameFilter
        && !this.employeeStatusFilter.length
        && !this.employeeOfficeFilter.length
        && !this.isEmptyHidden
      ) return this.matrixEmployees;

      const filteredEmployees = this.matrixEmployees
        .filter((matrixEmployee) => this.isEmployeeMatchingNameFilter(matrixEmployee)
        && this.isEmployeeMatchingStatusFilter(matrixEmployee)
        && this.isEmployeeMatchingOfficeFilter(matrixEmployee));

      return filteredEmployees;
    },
    filteredProjects() {
      const projectTitleFilter = this.projectFilter.trim();

      if (!projectTitleFilter && !this.isEmptyHidden) return this.projects;

      const filteredProjects = this.projects.filter((project) => this.isProjectMatchingNameFilter(project));

      return filteredProjects;
    },
    filtered() {
      if (!this.isEmptyHidden) return { employees: this.filteredEmployees, projects: this.filteredProjects };

      const employees = this.filteredEmployees.filter((employee) => this.isEmployeeMatchingHideFilter(employee));
      const projects = this.filteredProjects.filter((project) => this.isProjectMatchingHideFilter(project));

      return { employees, projects };
    },
    totalEmployees() {
      return this.filtered.employees.length || '...';
    },
    totalProjects() {
      return this.filtered.projects.length || '...';
    },
  },

  created() {
    this.$watch(
      () => [this.filteredProjects, this.filteredEmployees],
      () => this.calculateAndVisualiseHours(),
    );
  },

  methods: {
    defaultHoursModel: () => ({ time: 0, overtime: 0 }),
    setVisualisedHours(hours) {
      hours.timeVisual = this.convertTimeToVisualFormat(hours.time);
      hours.overtimedVisual = this.convertTimeToVisualFormat(hours.time + hours.overtime);
    },
    sumHours(hoursLeft, hoursRight) {
      hoursLeft.time += hoursRight.time;
      hoursLeft.overtime += hoursRight.overtime;

      return hoursLeft;
    },

    // #region Filter functions
    isEmployeeMatchingNameFilter(employee) {
      const employeeNameFilter = this.employeeFilter.trim();

      return (!employeeNameFilter || employee.name.toLowerCase().indexOf(employeeNameFilter.toLowerCase()) !== -1);
    },
    isEmployeeMatchingStatusFilter(employee) {
      return (!this.employeeStatusFilter.length || has(this.employeeStatusFilterMap, employee.status));
    },
    isEmployeeMatchingOfficeFilter(employee) {
      return (!this.employeeOfficeFilter.length || has(this.employeeOfficeFilterMap, employee.office_id));
    },
    isEmployeeMatchingHideFilter(employee) {
      if (!this.totalHoursByEmployees[employee.id]) return false;

      const { time, overtime } = this.totalHoursByEmployees[employee.id];

      return (time + Math.abs(overtime)) > 0;
    },
    isProjectMatchingNameFilter(project) {
      const projectTitleFilter = this.projectFilter.trim();

      return (!projectTitleFilter || project.title.toLowerCase().indexOf(projectTitleFilter.toLowerCase()) !== -1);
    },
    isProjectMatchingHideFilter(project) {
      if (!this.totalHoursByProjects[project.id]) return false;

      const { time, overtime } = this.totalHoursByProjects[project.id];

      return (time + Math.abs(overtime)) > 0;
    },
    // #endregion

    convertTimeToVisualFormat(minutes) {
      if (isNaN(minutes) || minutes === 0) return '0h';

      return `${(minutes / 60).toFixed(2)}h`;
    },

    getTimeFromHours(hours, defaultValue = hours) {
      if (defaultValue) {
        if (typeof hours !== 'object' || hours === null) return defaultValue;

        return this.isOVRMultiplyerApplied ? hours.overtimedVisual : hours.timeVisual;
      }

      return defaultValue;
    },

    calculateAndVisualiseHours() {
      const {
        defaultHoursModel,
        setVisualisedHours,
        sumHours,
      } = this;

      const commonTotalHours = defaultHoursModel(); // Total hours of all employees and projects
      const byProjects = {}; // Total employees hours by projects
      const byEmployees = {}; // Total projects hours by employees

      /* eslint-disable no-param-reassign */
      // Converting filtered projects list to Map for O(1) checking for project existence in filtered
      const filteredProjectsMap = this.filteredProjects.reduce((projectsMap, project) => {
        projectsMap[project.id] = project;

        return projectsMap;
      }, {});
      /* eslint-enable */

      // Running over employees and them projects for calculating all total sums
      this.filteredEmployees.forEach((employee) => {
        const employeeTotalHours = defaultHoursModel();

        /* Running over employee projects hours, filtering by projects
         * and calculating totals by projects and employee total hours. */
        for (const projectId in employee.projects_hours) {
          if (!has(filteredProjectsMap, projectId)) continue; // Filtering employee hours by filtered projects

          const projectHours = employee.projects_hours[projectId];

          byProjects[projectId] = byProjects[projectId] || defaultHoursModel();

          sumHours(byProjects[projectId], projectHours);
          sumHours(employeeTotalHours, projectHours);
        }

        // Visualising calculated employee total hours
        setVisualisedHours(employeeTotalHours);
        byEmployees[employee.id] = employeeTotalHours;

        // Calculating common total hours by summing employees total hours
        sumHours(commonTotalHours, employeeTotalHours);
      });

      setVisualisedHours(commonTotalHours); // Visualising calculated common total hours

      // Visualising calculated total hours by projects
      for (const projectId in byProjects) {
        if (!has(byProjects, projectId)) continue;

        setVisualisedHours(byProjects[projectId]);
      }

      this.commonTotalHours = commonTotalHours;
      this.totalHoursByProjects = byProjects;
      this.totalHoursByEmployees = byEmployees;
    },
    isOverLimit(employee, project) {
      return absenceTitles[project.title]
        && employee.projects_hours[project.id]
        && employee.projects_hours[project.id].over_limit;
    },
    isEmployeeProjectOverLimit(employee) {
      const employeeProjectIds = Object.keys(employee.projects_hours);

      return this.filteredProjects.some((proj) => employeeProjectIds.includes(proj.id) && this.isOverLimit(employee, proj));
    },
  },
};
</script>

<style lang="less">
@import "~variables";

#matrix-report-table {
  & > .v-data-table__wrapper > table {
    position: relative;
  }

  th, td {
    &.project-column {
      min-width: @projectColumnWidth;
      max-width: @projectColumnWidth;
      text-align: center !important;
    }
  }

  tbody > tr:hover {
    background: @black-5pct;
  }

  tbody td.project-column {
    padding: 0;

    & > span {
      padding: 0 16px;
    }

    &:hover {
      background: @black-5pct;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        display: block;
        background: @black-5pct;
        width: 96px;
        pointer-events: none;
      }
    }
  }

  thead th {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  thead .employee_info-col > table .employee_name-col,
  .employee_info-col > table .employee_total-col {
    border: solid rgba(0, 0, 0, 0.12) !important;
  }

  .employee_info-col > table {
    table-layout: fixed;
    width: 300px;
    height: 100%;
    border-spacing: 0;

    & > tr {
      background: transparent;
    }

    .employee_name-col {
      background: white;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 16px;
    }

    .employee_total-col {
      width: 96px;
      padding: 0 8px;
      border-width: 0 1px !important;
    }
  }

  thead .employee_info-col > table {
    td {
      font-size: inherit;
    }

    .employee_name-col {
      border-width: 0 0 1px !important;
    }

    .employee_total-col {
      border-width: 0 1px 1px 1px !important;
    }
  }

  tbody .employee_info-col > table {
    td {
      font-weight: normal;
      font-size: 1rem;
    }
  }

  tr.total-row {
    .employee_name-col {
      background-color: transparent;
    }

    .employee_total-col {
      border-bottom-width: 0 !important;
    }

    th {
      background-color: inherit;
      border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
  }

  &.v-data-table--dense {
    th, td {
      vertical-align: middle;
    }

    thead {
      th, td {
        height: @rowHeight;
        line-height: @rowHeight;
      }
    }

    tbody {
      th, td {
        height: @rowHeight;
        line-height: @rowHeight;
      }
    }
  }

  .no-padding {
    padding: 0;
  }

  .cell-content {
    display: inline-block;
    width: 100%;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
