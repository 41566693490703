/**
 * Creates an object composed of the picked object properties.
 * Returns whole object if no keys is provided
 *
 * @template {object} M - provided object type
 * @template {keyof M} Key - provided object key type
 * @param {M} map - provided object
 * @param {Key[]} [keys] - keys to pick from object
 * @returns {Pick<M, Key[][number]>} new object
 */
export const pick = (map, keys) => {
  if (!keys) return { ...map };

  const initial = /** @type {Pick<M, Key[][number]>} */({});

  /* eslint-disable no-param-reassign */
  const pickedObject = keys.reduce((obj, key) => {
    if (map && (key in map)) {
      obj[key] = map[key];
    }

    return obj;
  }, initial);
  /* eslint-enable */

  return pickedObject;
};
