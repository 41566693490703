export default {
  LEAV: {
    projectTitle: 'LEAV',
    taskTypeTitle: 'Paid Sick Leave',
    messageText: 'sick leaves',
  },
  VACP: {
    isVACPAbsenceCall: true,
    projectTitle: 'VACP',
    taskTypeTitle: 'Paid Vacation',
    messageText: 'vacation',
  },
};
