<template>
  <v-col
    id="matrix-report-page-filters"
    class="matrix-page-filters inputs-column"
  >
    <v-text-field
      class="secondary-background"
      label="Employee"
      hide-details
      outlined
      dense
      :value="employeeFilter"
      @input="setEmployeeFilter"
    />
    <v-text-field
      class="secondary-background"
      label="Project"
      hide-details
      outlined
      dense
      :value="projectFilter"
      @input="setProjectFilter"
    />
    <MultiChoiceFilter
      class="secondary-background"
      label="Employee status"
      item-text="title"
      hide-details
      outlined
      dense
      :items="employeeStatusFilterOptions"
      :value="employeeStatusFilter"
      @update="setEmployeeStatusFilter"
    />
    <MultiChoiceFilter
      class="secondary-background"
      label="Office"
      item-text="name"
      hide-details
      outlined
      dense
      :items="userOffices"
      :value="userOffices.length === 1 ? [userOffices[0].id] : employeeOfficeFilter"
      :disabled="userOffices.length === 1"
      @update="setEmployeeOfficeFilter"
    />
    <DateRange
      :value="dateRangeFilter"
      :display-format="dateRange"
      @input="customTimeRangeChange"
      @timeRangeMove="timeRangeMove"
    />
    <v-row>
      <v-col>
        <v-switch
          class="my-0"
          label="Apply OVR multiplier"
          color="primary"
          hide-details
          :input-value="isOVRMultiplyerApplied"
          @change="changeIsApplyOVRMultiplier"
        />
        <v-switch
          label="Show billable only"
          color="primary"
          hide-details
          :input-value="isBillableOnlyShown"
          @change="changeIsBillableOnlyShown"
        />
        <v-switch
          label="Show approved only"
          color="primary"
          hide-details
          :input-value="isApprovedOnlyShown"
          @change="changeIsApprovedOnlyShown"
        />
        <v-switch
          label="Show unapproved only"
          color="primary"
          hide-details
          :input-value="isUnapprovedOnlyShown"
          @change="changeIsUnapprovedOnlyShown"
        />
        <v-switch
          label="Hide empty"
          color="primary"
          hide-details
          :input-value="isEmptyHidden"
          @change="changeIsEmptyHidden"
        />
      </v-col>
      <v-col class="mt-2 interpretation-icons-alignment">
        <v-tooltip right>
          <template #activator="{ on }">
            <v-icon v-on="on">help_outline</v-icon>
          </template>
          <span>{{ matrixFilterTooltips.OVR_MULTIPLIER }}</span>
        </v-tooltip>
        <v-tooltip right>
          <template #activator="{ on }">
            <v-icon v-on="on">help_outline</v-icon>
          </template>
          <span>{{ matrixFilterTooltips.BILLABLE_ONLY }}</span>
        </v-tooltip>
        <v-tooltip right>
          <template #activator="{ on }">
            <v-icon v-on="on">help_outline</v-icon>
          </template>
          <span>{{ matrixFilterTooltips.APPROVED_ONLY }}</span>
        </v-tooltip>
        <v-tooltip right>
          <template #activator="{ on }">
            <v-icon v-on="on">help_outline</v-icon>
          </template>
          <span>{{ matrixFilterTooltips.UNAPPROVED_ONLY }}</span>
        </v-tooltip>
        <v-tooltip right>
          <template #activator="{ on }">
            <v-icon v-on="on">help_outline</v-icon>
          </template>
          <span>{{ matrixFilterTooltips.HIDE_EMPTY }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapCacheActions } from 'vuex-cache';

import { DateRange, MultiChoiceFilter } from '@/components/shared';
import { matrixFilterTooltips } from '@/constants/filters';
import { systemRolesNames } from '@/constants/roles';
import { DateHelper, DateFormat } from '@/helpers/date.helper';

export default {
  components: {
    DateRange,
    MultiChoiceFilter,
  },
  data: () => ({
    sm: 'sm2',
    matrixFilterTooltips,
  }),
  computed: {
    ...mapGetters('matrixReport/filters', [
      'employeeFilter',
      'employeeStatusFilter',
      'employeeStatusFilterOptions',
      'employeeOfficeFilter',
      'projectFilter',
      'dateRangeFilter',
      'isOVRMultiplyerApplied',
      'isBillableOnlyShown',
      'isApprovedOnlyShown',
      'isUnapprovedOnlyShown',
      'isEmptyHidden',
    ]),
    ...mapGetters('shared/roles', ['systemRoles']),
    ...mapGetters('shared/offices', ['offices']),
    ...mapGetters('auth/account', ['user']),

    dateRange() {
      const start = DateHelper.format(this.dateRangeFilter.startDate, DateFormat.DATE_WITH_SHORT_MONTH);
      const end = DateHelper.format(this.dateRangeFilter.endDate, DateFormat.DATE_WITH_SHORT_MONTH);

      return start === end ? `${start}` : `${start} - ${end}`;
    },

    userOffices() {
      const adminRole = this.systemRoles.find((role) => role.title === systemRolesNames.admin);

      if (!adminRole || this.user.role === adminRole.title) {
        return this.offices;
      }

      const userOffice = this.offices.find((office) => office.id === this.user.office);

      return userOffice ? [userOffice] : [];
    },
  },

  created() {
    this.getOffices();
    this.getSystemRoles();
  },

  methods: {
    ...mapActions('matrixReport/filters', [
      'moveDateRange',
      'changeCustomDateRange',
      'setEmployeeFilter',
      'setEmployeeStatusFilter',
      'setEmployeeOfficeFilter',
      'setProjectFilter',
      'setIsOVRMultiplyerApplied',
      'setIsBillableOnlyShown',
      'setIsApprovedOnlyShown',
      'setIsUnapprovedOnlyShown',
      'setIsEmptyHidden',
    ]),
    ...mapCacheActions('shared/roles', ['getSystemRoles']),
    ...mapCacheActions('shared/offices', ['getOffices']),

    async timeRangeMove(isForward) {
      await this.moveDateRange({
        isForward,
        dateRange: this.dateRangeFilter,
      });
      this.$emit('getFilteredMatrixReport');
    },
    async customTimeRangeChange(newDate) {
      await this.changeCustomDateRange(newDate);
      this.$emit('getFilteredMatrixReport');
    },
    changeIsApplyOVRMultiplier() {
      this.setIsOVRMultiplyerApplied(!this.isOVRMultiplyerApplied);
    },
    async changeIsBillableOnlyShown() {
      this.setIsBillableOnlyShown(!this.isBillableOnlyShown);
      this.$emit('getFilteredMatrixReport');
    },
    changeIsApprovedOnlyShown() {
      this.setIsApprovedOnlyShown(!this.isApprovedOnlyShown)
        .then(this.$emit('getFilteredMatrixReport'));
    },
    changeIsUnapprovedOnlyShown() {
      this.setIsUnapprovedOnlyShown(!this.isUnapprovedOnlyShown)
        .then(this.$emit('getFilteredMatrixReport'));
    },
    changeIsEmptyHidden() {
      this.setIsEmptyHidden(!this.isEmptyHidden);
    },
  },
};
</script>

<style lang="less">
#matrix-report-page-filters {
  .interpretation-icons-alignment {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
  }
}
</style>
