import { mapGetters, mapActions } from 'vuex';

import { pick } from '@/helpers/pick';

/**
 * @template T
 * @typedef {import('@/store/types/mapped-state').MappedState<T>} MappedState<T>
 */
/**
 * @typedef {import('./matrix-report-report.actions').MatrixReportReportActions} MatrixReportReportActions
 * @typedef {import('./matrix-report-report.getters').MatrixReportReportGetters} MatrixReportReportGetters
 * @typedef {import('./matrix-report-report.state').MatrixReportReportState} MatrixReportReportState
 */

/** @type {MatrixReportReportActions} */
const mappedMatrixReportReportActions = {
  ...mapActions('matrixReport/report', ['getMatrixReport']),
};

/**
 * @template {keyof typeof mappedMatrixReportReportActions} A
 * @param {A[]} [actions]
 * @returns {Pick<typeof mappedMatrixReportReportActions, A[][number]>}
 */
export const mapMatrixReportReportActions = (actions) => pick(mappedMatrixReportReportActions, actions);

/** @type {MappedState<MatrixReportReportState>} */
const mappedMatrixReportReportState = {
  ...mapGetters('matrixReport/report', ['matrixReport']),
};

/**
 * @template {keyof typeof mappedMatrixReportReportState} S
 * @param {S[]} [states]
 * @returns {Pick<typeof mappedMatrixReportReportState, S[][number]>}
 */
export const mapMatrixReportReportState = (states) => pick(mappedMatrixReportReportState, states);
