<template>
  <v-row class="pa-4">
    <v-col
      v-if="isFiltersOpened"
      xl="2"
      lg="3"
    >
      <v-card class="pa-4">
        <v-card-title class="small-title">
          Filters
        </v-card-title>
        <MatrixReportPageFilters
          @getFilteredMatrixReport="getFilteredMatrixReport"
        />
      </v-card>
    </v-col>

    <v-col
      :xl="isFiltersOpened ? 10 : 12"
      :lg="isFiltersOpened ? 9 : 12"
    >
      <v-card>
        <v-row class="pa-4 pb-0">
          <v-col>
            <v-card-title class="small-title">
              <v-btn
                icon
                class="mr-2"
                @click="(isFiltersOpened = !isFiltersOpened)"
              >
                <v-icon>mdi-filter-variant</v-icon>
              </v-btn>
              Matrix report
            </v-card-title>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <Button
                  id="matrix-report-export-to-excel-button"
                  color="primary"
                  :on="!largeScreenHelper ? on : null"
                  :action-names="[loadingTypes.matrixReportExportToExcelButton]"
                  @clickAction="showDialogHandler"
                >
                  <v-icon v-if="!largeScreenHelper">mdi-file-table</v-icon>
                  <span v-else>{{ exportToExcelText }}</span>
                </Button>
              </template>
              <span>{{ exportToExcelText }}</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <MatrixReportTable
          :items="matrixReport"
          :highlight="highlight"
        />
      </v-card>
    </v-col>
    <ExportToExcelDialog
      :opened="isExcelExportDialogOpen"
      @close="isExcelExportDialogOpen = false"
      @addRaftLogo="addRaftLogoHandler"
    />
  </v-row>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';
import { mapCacheActions } from 'vuex-cache';

import { Button } from '@/components/shared';
import { loadingTypes } from '@/constants/loadingTypes';
import { NotificationHelper } from '@/helpers/notification.helper';
import { isLargeScreen } from '@/helpers/screen.helper';
import apiClient from '@/services/api-client';
import {
  mapMatrixReportReportActions,
  mapMatrixReportReportState,
} from '@/store/matrix-report/report/matrix-report-report.mappers';

import ExportToExcelDialog from '../shared/ExportToExcelDialog.vue';

import MatrixReportPageFilters from './MatrixReportPageFilters.vue';
import MatrixReportTable from './MatrixReportTable.vue';

export default {
  components: {
    Button,
    MatrixReportPageFilters,
    MatrixReportTable,
    ExportToExcelDialog,
  },
  data() {
    return {
      exportToExcelText: 'Export to Excel',
      loadingTypes,
      isFiltersOpened: true,
      highlight: false,
      isExcelExportDialogOpen: false,
      addRaftLogoValue: true,
    };
  },
  computed: {
    ...mapGetters('matrixReport/filters', [
      'dateRangeFilter',
      'isBillableOnlyShown',
      'isApprovedOnlyShown',
      'isUnapprovedOnlyShown',
      'isOVRMultiplyerApplied',
      'isEmptyHidden',
      'employeeFilter',
      'employeeStatusFilter',
      'employeeOfficeFilter',
      'projectFilter',
    ]),
    ...mapMatrixReportReportState(['matrixReport']),
    ...mapGetters('projects/main', ['projects']),
    ...mapGetters('auth/account', ['user']),

    largeScreenHelper() {
      return isLargeScreen(this.$vuetify.breakpoint, false);
    },
  },
  async created() {
    await this.getProjects(this.user.id);
    this.isHiglight();
    this.getMatrixReport({
      startDate: this.dateRangeFilter.startDate,
      endDate: this.dateRangeFilter.endDate,
      billableOnly: this.isBillableOnlyShown,
      approvedOnly: this.isApprovedOnlyShown,
      unapprovedOnly: this.isUnapprovedOnlyShown,
    });
  },
  methods: {
    ...mapMatrixReportReportActions(['getMatrixReport']),
    ...mapCacheActions('projects/main', ['getProjects']),

    getMatrixReportDebounced: debounce(function (args) {
      this.getMatrixReport(args);
    }, 500),
    getFilteredMatrixReport() {
      this.getMatrixReportDebounced({
        startDate: this.dateRangeFilter.startDate,
        endDate: this.dateRangeFilter.endDate,
        billableOnly: this.isBillableOnlyShown,
        approvedOnly: this.isApprovedOnlyShown,
        unapprovedOnly: this.isUnapprovedOnlyShown,
      });
    },
    showDialogHandler() {
      this.isExcelExportDialogOpen = true;
    },
    /** @param {boolean} data */
    addRaftLogoHandler(data) {
      this.addRaftLogoValue = data;
      this.exportToExcel();
    },
    async exportToExcel() {
      const response = await apiClient.matrixReportApi
        .exportMatrixReportToExcel({
          startDate: this.dateRangeFilter.startDate,
          endDate: this.dateRangeFilter.endDate,
          billableOnly: this.isBillableOnlyShown,
          approvedOnly: this.isApprovedOnlyShown,
          isOVRMultiplyerApplied: this.isOVRMultiplyerApplied,
          employee: this.employeeFilter,
          projects: this.projectFilter,
          employeeStatus: this.employeeStatusFilter,
          officeIds: this.employeeOfficeFilter,
          hideEmpty: this.isEmptyHidden,
          unapprovedOnly: this.isUnapprovedOnlyShown,
          includeLogo: this.addRaftLogoValue,
        }, { loadingType: loadingTypes.matrixReportExportToExcelButton });

      this.$saveFile(response);
    },
    async  isHiglight() {
      try {
        const response = await apiClient.permissionsApi.getAbsenceCalc();

        this.highlight = response.data.allowed;
      } catch (error) {
        if (error.response && error.response.data.day_limit) {
          const errorMessage = NotificationHelper
            .formatLoggedTimeErrorMessage(error.response.data, this.dateFormatSettings);

          NotificationHelper.showError(errorMessage);
        } else {
          throw error;
        }
      }
    },

  },
};
</script>

<style>
</style>
